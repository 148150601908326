import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { _head,_varibs,_items,order_master } from './model';



@Injectable({ providedIn: 'root' })
export class Http_client_Service {

  //  private postUrl = 'http://localhost:4201/api/post/';
  //  private getUrl =  'http://localhost:4201/api/get/';

  private getUrl = 'https://ofos.asajaitsolutions.com/ofos-api/get/';
  private postUrl =  'https://ofos.asajaitsolutions.com/ofos-api/post/';



  is_shop_updation : boolean = false;
  user_id : number = 0;
  user_role : string = '';
  status_flag : number = -2;
  click_status : number = -2;
  order_id : number = 0;
  com_id : any;
  selected_order :  any [] = [];
  rej_reason : string = '';
  order_person_name : string = '';

    o_h = new _head(0,'','','',new Date(),0,-1,'',0,0)
    o_v = new _varibs(0,0)
    o_i = new _items(0,'',0,0,'','','','','',0,new Date(),'','')
    o_m = new order_master(this.o_h,[],this.o_v);




  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(  private http: HttpClient ) { }




  login(model:any)
  {
    let body = JSON.stringify(model);
    console.log('body',body);
    return this.http.post(this.postUrl + 'login', body, this.httpOptions).pipe(

      catchError(this.handleError<any>('login',body))
    );
  }

  add_company_details(model:any)
  {
    let body = JSON.stringify(model);
    console.log('body',body);
    return this.http.post(this.postUrl + 'add_company_details', body, this.httpOptions).pipe(
      catchError(this.handleError<any>('add_company_details ',body))
    );
  }

  update_company_details(model:any)
  {
    let body = JSON.stringify(model);
    console.log('body',body);
    return this.http.post(this.postUrl + 'update_company_details', body, this.httpOptions).pipe(catchError(this.handleError<any>('update_company_details ',body)));
  }

  get_company_dets(id: number): Observable<any> {
    const url = `${this.getUrl + 'get_company_dets'}/${id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(
      catchError(this.handleError<any>('get_company_dets'))
    );
  }

  get_holding_order_list(id: number): Observable<any> {
    const url = `${this.getUrl + 'get_holding_order_list'}/${id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(
      catchError(this.handleError<any>('get_holding_order_list'))
    );
  }


  get_holding_order_count(id:number) :  Observable<any> {

    const url = `${this.getUrl + 'get_holding_order_count'}/${id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(
      catchError(this.handleError<any>('get_holding_order_count'))
    );

  }

  get_active_order_count(id: number): Observable<any> {

    const url = `${this.getUrl + 'get_active_order_count'}/${id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(
      catchError(this.handleError<any>('get_active_order_count'))
    );
  }


  get_despatched_order_count(id:number) :  Observable<any> {

    const url = `${this.getUrl + 'get_despatched_order_count'}/${id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(
      catchError(this.handleError<any>('get_despatched_order_count'))
    );

  }

  get_cancelled_order_count(id: number): Observable<any> {
    const url = `${this.getUrl + 'get_cancelled_order_count'}/${id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(
      catchError(this.handleError<any>('get_cancelled_order_count'))
    );
  }

  get_rejected_order_count(id: number): Observable<any> {
    const url = `${this.getUrl + 'get_rejected_order_count'}/${id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(
      catchError(this.handleError<any>('get_rejected_order_count'))
    );
  }







  get_active_order_count_admin(com_id:number) : Observable<any> {
    const url = `${this.getUrl + 'get_active_order_count_admin'}/${com_id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(
      catchError(this.handleError<any>('get_active_order_count_admin'))
    );
    }

    get_despatched_order_count_admin(id: number):  Observable<any> {

      const url = `${this.getUrl + 'get_despatched_order_count_admin'}/${id}`;
      return this.http.get<any>(url ,this.httpOptions).pipe(
        catchError(this.handleError<any>('get_despatched_order_count_admin'))
      );
    }

  get_cancelled_order_count_admin(id: number): Observable<any> {
    const url = `${this.getUrl + 'get_cancelled_order_count_admin'}/${id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(
      catchError(this.handleError<any>('get_cancelled_order_count_admin'))
    );
  }

  get_rejected_order_count_admin(id: number): Observable<any> {
    const url = `${this.getUrl + 'get_rejected_order_count_admin'}/${id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(
      catchError(this.handleError<any>('get_rejected_order_count_admin'))
    );
  }


  get_all_products_on_sub_sub_cat_selected(model:any)
   {
    let body = JSON.stringify(model);
    console.log('body',body);
    return this.http.post(this.postUrl + 'get_all_products_on_sub_sub_cat_selected', body, this.httpOptions).pipe(
      catchError(this.handleError<any>('get_all_products_on_sub_sub_cat_selected',body))
    );
   }














  reject_order(model:any): Observable<any> {
    let body = JSON.stringify(model);
    console.log('body',body);
    return this.http.post(this.postUrl + 'reject_order', body, this.httpOptions).pipe(

      catchError(this.handleError<any>('reject_order',body))
    );
  }

  cancel_new_order(id: number,user_id:number): Observable<any> {
    const url = `${this.getUrl + 'cancel_new_order'}/${id}/${user_id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(
      catchError(this.handleError<any>('cancel_new_order'))
    );
  }

  execute_order(id: number,user_id:number): Observable<any> {
    const url = `${this.getUrl + 'execute_order'}/${id}/${user_id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(
      catchError(this.handleError<any>('execute_order'))
    );
  }

  revoke_order(id: number,user_id:number): Observable<any> {
    const url = `${this.getUrl + 'revoke_order'}/${id}/${user_id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(
      catchError(this.handleError<any>('revoke_order'))
    );
  }

  submit_holding_order(id: number)
  {
    const url = `${this.getUrl + 'submit_holding_order'}/${id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(
      catchError(this.handleError<any>('submit_holding_order'))
    );
  }

  cancel_holding_order(id: number)
  {
    const url = `${this.getUrl + 'cancel_holding_order'}/${id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(
      catchError(this.handleError<any>('cancel_holding_order'))
    );
  }


  get_order_detail(model:any)
  {
    let body = JSON.stringify(model);
    console.log('body',body);
    return this.http.post(this.postUrl + 'get_order_detail', body, this.httpOptions).pipe(

      catchError(this.handleError<any>('get_order_detail',body))
    );
  }


  get_order_detail_for_edit(id: number): Observable<any> {
    const url = `${this.getUrl + 'get_order_detail_for_edit'}/${id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(

      catchError(this.handleError<any>('get_order_detail_for_edit'))
    );
  }


  get_order_detail_by_id(id: number): Observable<any> {
    const url = `${this.getUrl + 'get_order_detail_by_id'}/${id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(

      catchError(this.handleError<any>('get_order_detail_by_id'))
    );
  }

  get_order_person_detail_by_id(id: number): Observable<any> {
    const url = `${this.getUrl + 'get_order_person_detail_by_id'}/${id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(

      catchError(this.handleError<any>('get_order_person_detail_by_id'))
    );
  }



  get_active_customer_count(com_id:number)
   {
    const url = `${this.getUrl + 'get_active_customer_count'}/${com_id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(catchError(this.handleError<any>('get_active_customer_count'))
    );
   }




   get_all_order_list(info:any)
   {

    let body = JSON.stringify(info);
    console.log('body',info);
    return this.http.post(this.getUrl + 'get_all_order_list', body, this.httpOptions).pipe(
      catchError(this.handleError<any>('get_all_order_list',body))
    );



   }







  post_new_order(model: any): Observable<any> {
    let body = JSON.stringify(model);
    console.log('body',body);
    return this.http.post(this.postUrl + 'post_new_order', body, this.httpOptions).pipe(

      catchError(this.handleError<any>('post_new_order',body))
    );

  }

  register_new_user(model: any): Observable<any> {
    let body = JSON.stringify(model);  console.log('body',body);
    return this.http.post(this.postUrl + 'register_new_user', body, this.httpOptions).pipe(catchError(this.handleError<any>('register_new_user',body)));
  }


  update_order(model: any): Observable<any> {
    let body = JSON.stringify(model);
    console.log('body',body);
    return this.http.post(this.postUrl + 'update_order', body, this.httpOptions).pipe(

      catchError(this.handleError<any>('update_order',body))
    );

  }

    add_new_user(model :any): Observable<any> {
      let body = JSON.stringify(model)
      return this.http.post(this.postUrl + 'add_new_user', body, this.httpOptions).pipe(

        catchError(this.handleError<any>('add_new_user',body))
      );


    }
    update_user_details(model :any): Observable<any> {
      let body = JSON.stringify(model)
      return this.http.post(this.postUrl + 'update_user_details', body, this.httpOptions).pipe(

        catchError(this.handleError<any>('update_user_details',body))
      );


    }





    get_all_shop_details(id: number)
    {

      const url = `${this.getUrl + 'get_all_shop_details'}/${id}`;
        return this.http.get<any>(url ,this.httpOptions).pipe(catchError(this.handleError<any>('get_all_shop_details')));

    }


    add_new_product(model :any): Observable<any> {

      let body = JSON.stringify(model);
      console.log('body',body)
      return this.http.post(this.postUrl + 'add_new_product', body, this.httpOptions).pipe(

        catchError(this.handleError<any>('add_new_product',body))
      );


    }


    add_new_shop(model :any): Observable<any> {
      let body = JSON.stringify(model)
      return this.http.post(this.postUrl + 'add_new_shop', body, this.httpOptions).pipe(

        catchError(this.handleError<any>('add_new_shop',body))
      );


    }

    get_all_sub_cats_inde(id: number)
    {

      const url = `${this.getUrl + 'get_all_sub_cats_inde'}/${id}`;
        return this.http.get<any>(url ,this.httpOptions).pipe(catchError(this.handleError<any>('get_all_sub_cats_inde')));

    }

    get_all_sub_sub_cats_inde(id: number)
    {
        const url = `${this.getUrl + 'get_all_sub_sub_cats_inde'}/${id}`;
        return this.http.get<any>(url ,this.httpOptions).pipe(catchError(this.handleError<any>('get_all_sub_sub_cats_inde')));

    }


    update_exsiting_shop(model :any): Observable<any>
     {
      let body = JSON.stringify(model);
      console.log('body',body)
      return this.http.post(this.postUrl + 'update_exsiting_shop', body, this.httpOptions).pipe(

        catchError(this.handleError<any>('update_exsiting_shop',body))
      );


    }

    delete_exsiting_shop(model :any): Observable<any>
    {
     let body = JSON.stringify(model);
     console.log('body',body)
     return this.http.post(this.postUrl + 'delete_exsiting_shop', body, this.httpOptions).pipe(

       catchError(this.handleError<any>('delete_exsiting_shop',body))
     );


   }


   get_all_products(): Observable<any> {
    return this.http.get(this.getUrl + 'get_all_products', this.httpOptions).pipe(catchError(this.handleError<any>('get_all_products')));
  }

  get_all_products_after_filter(model :any): Observable<any>
  {
   let body = JSON.stringify(model);
   console.log('body',body)
   return this.http.post(this.postUrl + 'get_all_products_after_filter', body, this.httpOptions).pipe(catchError(this.handleError<any>('get_all_products_after_filter',body)));
 }


  add_new_category(model :any): Observable<any> {
      let body = JSON.stringify(model)
      return this.http.post(this.postUrl + 'add_new_category', body, this.httpOptions).pipe(

        catchError(this.handleError<any>('add_new_category',body))
      );


    }
    add_new_sub_category(model :any): Observable<any> {
      let body = JSON.stringify(model)
      return this.http.post(this.postUrl + 'add_new_sub_category', body, this.httpOptions).pipe(

        catchError(this.handleError<any>('add_new_sub_category',body))
      );


    }

    add_new_sub_sub_category(model :any): Observable<any> {
      let body = JSON.stringify(model)
      return this.http.post(this.postUrl + 'add_new_sub_sub_category', body, this.httpOptions).pipe(

        catchError(this.handleError<any>('add_new_sub_sub_category',body))
      );


    }




    get_all_sub_cat_on_change_cat(model :any): Observable<any> {
      let body = JSON.stringify(model)
      return this.http.post(this.postUrl + 'get_all_sub_cat_on_change_cat', body, this.httpOptions).pipe(
        catchError(this.handleError<any>('get_all_sub_cat_on_change_cat',body))
      );
    }

    get_all_sub_sub_cat_on_change_sub_cat(model :any): Observable<any> {
      let body = JSON.stringify(model);
      console.log('body',body);
      return this.http.post(this.postUrl + 'get_all_sub_sub_cat_on_change_sub_cat', body, this.httpOptions).pipe(
        catchError(this.handleError<any>('get_all_sub_sub_cat_on_change_sub_cat',body))
      );
    }





    delete_product(model :any): Observable<any> {
      let body = JSON.stringify(model)
      return this.http.post(this.postUrl + 'delete_product', body, this.httpOptions).pipe(

        catchError(this.handleError<any>('delete_product',body))
      );


    }



    get_all_sub_cats(): Observable<any> {

      return this.http.get(this.getUrl + 'get_all_sub_cats', this.httpOptions).pipe(

        catchError(this.handleError<any>('get_all_sub_cats'))
      );


    }

    get_all_cats(id:number): Observable<any> {

      const url = `${this.getUrl + 'get_all_cats'}/${id}`;
      return this.http.get<any>(url ,this.httpOptions).pipe(
        catchError(this.handleError<any>('get_all_cats'))
      );

    }


    get_all_holding_orders_list()
    {
     return this.http.get(this.getUrl + 'get_all_holding_orders_list', this.httpOptions).pipe(catchError(this.handleError<any>('get_all_holding_orders_list')));
    }


            delete_sub_sub_cat(model:any): Observable<any> {
              let body = JSON.stringify(model)
              return this.http.post(this.postUrl + 'delete_sub_sub_cat',body, this.httpOptions).pipe(catchError(this.handleError<any>('delete_sub_sub_cat',body)));
            }


             delete_sub_cat(model:any): Observable<any> {
              let body = JSON.stringify(model)
              return this.http.post(this.postUrl + 'delete_sub_cat',body, this.httpOptions).pipe(catchError(this.handleError<any>('delete_sub_cat',body)));
             }

             delete_cat(model:any): Observable<any> {
              let body = JSON.stringify(model)
              return this.http.post(this.postUrl + 'delete_cat',body, this.httpOptions).pipe(
                catchError(this.handleError<any>('delete_cat',body))
              );
             }


            get_all_users_of_com_id(id:number): Observable<any> {
              console.log('id',id)
              const url = `${this.getUrl + 'get_all_users_of_com_id'}/${id}`;
              return this.http.get<any>(url ,this.httpOptions).pipe(
                catchError(this.handleError<any>('get_all_users_of_com_id'))
              );

            }



  //////// Save methods //////////

  /** POST: add a new hero to the server */
  // addHero(hero: Hero): Observable<Hero> {
  //   return this.http.post<Hero>(this.heroesUrl, hero, this.httpOptions).pipe(
  //     tap((newHero: Hero) => this.log(`added hero w/ id=${newHero.id}`)),
  //     catchError(this.handleError<Hero>('addHero'))
  //   );
  // }

  // /** DELETE: delete the hero from the server */
  // deleteHero(hero: Hero | number): Observable<Hero> {
  //   const id = typeof hero === 'number' ? hero : hero.id;
  //   const url = `${this.heroesUrl}/${id}`;

  //   return this.http.delete<Hero>(url, this.httpOptions).pipe(
  //     tap(_ => this.log(`deleted hero id=${id}`)),
  //     catchError(this.handleError<Hero>('deleteHero'))
  //   );
  // }

  /** PUT: update the hero on the server */


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead



      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


}


